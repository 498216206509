
import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";
import { FormMoney, FormInput, FormTextarea } from "@/components/forms";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        FormMoney,
        FormInput,
        FormTextarea,
        ValidationProvider,
    },
})
export default class PropertyPartConstructionExteriorJoinery extends Mixins(HasProperyPart) {
    @Prop({ required: true }) value?: PropertyConstructionExteriorJoinery;

    localValue: PropertyConstructionExteriorJoinery = {
        windows: [],
        material: [],
        notes: null,
    };

    mounted() {}

    toggleArrayProperty(property: string[], value: string) {
        this.toggleArrayPropertyValue(property, value);

        this.handleInput();
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: PropertyConstructionExteriorJoinery) {
        this.localValue = newValue;
    }
}
